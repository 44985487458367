import React from 'react'
import { IconProps } from '../../types/types'

export default function IconMenu({ color = '#111315', size = 24 }: IconProps) {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_3113_57193)">
                    <path d="M20.762 10.7607H3.237C2.9141 10.7699 2.60748 10.9046 2.38232 11.1363C2.15717 11.3679 2.03125 11.6782 2.03125 12.0012C2.03125 12.3243 2.15717 12.6346 2.38232 12.8662C2.60748 13.0978 2.9141 13.2326 3.237 13.2417H20.763C21.0859 13.2326 21.3925 13.0978 21.6177 12.8662C21.8428 12.6346 21.9688 12.3243 21.9688 12.0012C21.9688 11.6782 21.8428 11.3679 21.6177 11.1363C21.3925 10.9046 21.0859 10.7699 20.763 10.7607H20.762Z" fill={color} />
                    <path d="M12.4872 6.48149H20.7612C20.927 6.4862 21.0921 6.4576 21.2468 6.39738C21.4014 6.33716 21.5424 6.24653 21.6614 6.13087C21.7804 6.01521 21.8749 5.87689 21.9395 5.72404C22.0041 5.57119 22.0374 5.40694 22.0374 5.24101C22.0374 5.07507 22.0041 4.91083 21.9395 4.75798C21.8749 4.60513 21.7804 4.46677 21.6614 4.35112C21.5424 4.23546 21.4014 4.14483 21.2468 4.08461C21.0921 4.02438 20.927 3.99579 20.7612 4.0005H12.4872C12.3213 3.99579 12.1561 4.02438 12.0015 4.08461C11.8469 4.14483 11.706 4.23546 11.587 4.35112C11.468 4.46677 11.3734 4.60513 11.3088 4.75798C11.2442 4.91083 11.2109 5.07507 11.2109 5.24101C11.2109 5.40694 11.2442 5.57119 11.3088 5.72404C11.3734 5.87689 11.468 6.01521 11.587 6.13087C11.706 6.24653 11.8469 6.33716 12.0015 6.39738C12.1561 6.4576 12.3213 6.4862 12.4872 6.48149Z" fill={color} />
                    <path d="M11.5149 17.5195H3.24091C2.918 17.5286 2.61139 17.6634 2.38623 17.895C2.16108 18.1266 2.03516 18.4369 2.03516 18.76C2.03516 19.083 2.16108 19.3933 2.38623 19.625C2.61139 19.8566 2.918 19.9913 3.24091 20.0005H11.5149C11.8378 19.9913 12.1444 19.8566 12.3696 19.625C12.5947 19.3933 12.7207 19.083 12.7207 18.76C12.7207 18.4369 12.5947 18.1266 12.3696 17.895C12.1444 17.6634 11.8378 17.5286 11.5149 17.5195Z" fill={color} />
                </g>
                <defs>
                    <clipPath id="clip0_3113_57193">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}
