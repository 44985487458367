import React from 'react'
import { IconProps } from '../../types/types';

export default function IconFacebook({ color = '#111315', size = 22 }: IconProps) {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 22 22" fill="none" className='group'>
                <g clipPath="url(#clip0_2985_36879)">
                    <path d="M21.0944 10.305C21.0944 4.6137 16.4807 0 10.7894 0C5.09808 0 0.484375 4.6137 0.484375 10.305C0.484375 15.4484 4.25274 19.7117 9.17922 20.4848V13.2838H6.56272V10.305H9.17922V8.03468C9.17922 5.45199 10.7177 4.02539 13.0716 4.02539C14.1987 4.02539 15.3783 4.22666 15.3783 4.22666V6.76266H14.0789C12.7989 6.76266 12.3995 7.55707 12.3995 8.37281V10.305H15.2576L14.8007 13.2838H12.3995V20.4848C17.326 19.7117 21.0944 15.4484 21.0944 10.305Z" fill={color} className='group-hover:fill-[#23F4F9]' />
                </g>
                <defs>
                    <clipPath id="clip0_2985_36879">
                        <rect width="20.61" height="20.61" fill="white" transform="translate(0.484375)" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}
