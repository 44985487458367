import React from 'react';
import { styled } from 'styled-components';
import Logo from '../components/modules/Logo';
import Button from '../components/modules/Button';
import { IconProps, LinkItemType } from '../types/types';

// SocialMedia Icons
import IconDiscord from '../components/icons/IconDiscord';
import IconTwitter from '../components/icons/IconTwitter';
import IconInstagram from '../components/icons/IconInstagram';
import IconTelegram from '../components/icons/IconTelegram';
import IconYoutube from '../components/icons/IconYoutube';
import IconFacebook from '../components/icons/IconFacebook';
import IconReddit from '../components/icons/IconReddit';
import IconMedium from '../components/icons/IconMedium';
import IconGithub from '../components/icons/IconGithub';
import { v4 } from 'uuid';

const footerSocialLinks: {
    link: string;
    Icon: ({ color, size }: IconProps) => React.JSX.Element;
}[] = [
        { link: '', Icon: IconDiscord },
        { link: '', Icon: IconTwitter },
        { link: '', Icon: IconInstagram },
        { link: '', Icon: IconTelegram },
        { link: '', Icon: IconYoutube },
        { link: '', Icon: IconFacebook },
        { link: '', Icon: IconReddit },
        { link: '', Icon: IconMedium },
        { link: '', Icon: IconGithub },
    ]

const footerLinks: LinkItemType[] = [
    { linkText: 'APIs', link: '#openSourcePlugins' },
    { linkText: 'plugins', link: '#openSourcePlugins' },
    { linkText: 'coins', link: '/coins' },
    { linkText: 'fees', link: '/fees' },
    { linkText: 'sing up', link: '/' },
    { linkText: 'login', link: '/' },
]

const FooterTag = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 28px;
    padding: 2rem 1rem;
    background-color: #0C0E10;
    height: max-content;
    width: 100%;
    position: absolute;
    top: 100%;
    bottom: 0;
    left: 0;
    right: 0;
`

const Stack = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    gap: 6px;
`

const Footer = () => {
    return (
        <FooterTag className='border-t-2 border-neutral-dark-outline z-10'>
            <Logo />
            <Stack>
                {
                    footerLinks.map(link => {
                        return (
                            <Button className='hover:text-[#23F4F9]' key={v4()} href={link.link}>
                                {link.linkText}
                            </Button>
                        )
                    })
                }
            </Stack>
            <div className='flex flex-col-reverse md:flex-row justify-between items-center w-full gap-7'>
                <span className='text-center text-neutral-dark-gray3'>
                    Copyright &copy; 2023 All-in-one Token . All Rights Reserved.
                </span>
                <Stack>
                    {
                        footerSocialLinks.map(link => {
                            return (
                                <Button key={v4()} size='sm' href={link.link}>
                                    <link.Icon color='#FFF' size={20} />
                                    {link.link}
                                </Button>
                            )
                        })
                    }
                </Stack>
            </div>
        </FooterTag>
    )
}

export default Footer