import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { globalHistory } from '@reach/router'

type ContextProviderProps = {
    children: React.ReactNode,
}

interface LayoutState {
    isDarkMode?: boolean;
    isMenuOpen?: boolean,
}

type LayoutContextType = {
    layoutState: LayoutState,
    updateLayoutState: (newState: LayoutState) => void
}

const LayoutContext = createContext<LayoutContextType>({
    layoutState: {},
    updateLayoutState: () => { }
});

const LayoutProvider = ({ children }: ContextProviderProps) => {

    const [layoutState, setLayoutState] = useState<LayoutState>({
        isDarkMode: false,
        isMenuOpen: false
    });

    const updateLayoutState = useCallback((newState: LayoutState) =>
        setLayoutState({ ...layoutState, ...newState }),
        [layoutState]
    )
    globalHistory.listen(() => {
        if (layoutState.isMenuOpen) {
            setLayoutState(prevState => {
                return {
                    ...prevState,
                    isMenuOpen: false
                }
            })
        }

    })

    const value = {
        layoutState,
        updateLayoutState
    }

    return (
        <LayoutContext.Provider value={value}>
            {children}
        </LayoutContext.Provider>
    )
}

export default LayoutProvider;

export const useLayoutState = () => {
    return useContext(LayoutContext);

}