import React from 'react'
import { IconProps } from '../../types/types';

export default function IconTwitter({ color = '#111315', size = 22 }: IconProps) {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 22 22" fill="none" className='group'>
                <g clipPath="url(#clip0_2985_36865)">
                    <path d="M6.58227 18.6802C14.3588 18.6802 18.6134 12.2359 18.6134 6.64907C18.6134 6.4679 18.6094 6.28271 18.6014 6.10154C19.429 5.50299 20.1433 4.76161 20.7106 3.91222C19.9397 4.25517 19.1213 4.47915 18.2833 4.57651C19.1657 4.04759 19.8264 3.21669 20.1429 2.23783C19.3128 2.72978 18.405 3.07681 17.4584 3.26404C16.8206 2.58635 15.9773 2.13764 15.0589 1.98728C14.1405 1.83692 13.1982 1.99329 12.3776 2.43222C11.557 2.87114 10.9038 3.56817 10.5191 4.41553C10.1343 5.2629 10.0395 6.21341 10.2491 7.12011C8.56826 7.03576 6.92391 6.59912 5.42264 5.8385C3.92137 5.07788 2.59671 4.01025 1.53452 2.70484C0.994661 3.63562 0.829463 4.73704 1.0725 5.78524C1.31554 6.83345 1.94858 7.74979 2.84296 8.34802C2.17151 8.32671 1.51477 8.14593 0.927004 7.82062V7.87296C0.926402 8.84975 1.26409 9.7966 1.88266 10.5526C2.50123 11.3085 3.3625 11.8269 4.32008 12.0197C3.69809 12.1899 3.04529 12.2147 2.41218 12.0922C2.68239 12.9322 3.20812 13.6669 3.916 14.1938C4.62388 14.7207 5.47859 15.0134 6.36084 15.0311C4.86304 16.2077 3.01281 16.8458 1.10817 16.8428C0.770399 16.8423 0.432959 16.8216 0.0976562 16.7808C2.03257 18.0221 4.28339 18.6814 6.58227 18.6802Z" fill={color} className='group-hover:fill-[#23F4F9]' />
                </g>
                <defs>
                    <clipPath id="clip0_2985_36865">
                        <rect width="20.6129" height="20.6129" fill="white" transform="translate(0.0976562)" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}
