import { GatsbyImage, MainImage, StaticImage } from "gatsby-plugin-image";
import React from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";

type LogoProps = {
  responsive?: boolean;
};

const Logo = ({ responsive = false }: LogoProps) => {

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-light.svg" }) {
        publicURL
      }
    }
  `);

  const logo = data.logo.publicURL

  return (
    <div className="flex items-center gap-2 cursor-pointer" onClick={() => navigate('/')}>
      <img
        width={160}
        height={30}
        src={logo}
        alt="logo"
      />
    </div>
  );
};

export default Logo;
