import React, { useEffect } from 'react';
import { v4 } from 'uuid';
import { styled } from 'styled-components';
import Button from '../Button';
import Drawer from './Drawer';
import { LinkItemType } from '../../../types/types';

const Nav = styled.nav`
    width: max-content;
    height: 100%;
`;

const NavItems = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    gap: 8px;
`;


type NavbarProps = {
    className?: string
}

export const items: LinkItemType[] = [
    { linkText: 'Home', link: '/' },
    { linkText: 'APIs', link: '/#openSourcePlugins' },
    { linkText: 'plugins', link: '/plugins' },
    { linkText: 'coins', link: '/coins' },
    { linkText: 'fees', link: '/fees' },
    { linkText: 'about us', link: '/about-us' },
    { linkText: 'contact us', link: '/contact-us' },
]

const Navbar = ({ className }: NavbarProps) => {

    const setActiveRoute = () => {
        let allLinks = document.querySelectorAll('nav ul a.active');
        for (let i = 0; i < allLinks.length; i++) {
            const element = allLinks[i];
            element?.classList.remove('active');
        }
        const basePathname = window.location.pathname;

        const baseSelector = document.querySelector('nav ul a[href^="' + basePathname + '"]');
        baseSelector?.classList.add('active');
    };

    useEffect(() => {
        setActiveRoute()
    }, [window.location.pathname, window.location.href, window.location])


    return (
        <>
            <Nav className={className}>
                <NavItems>
                    {
                        items.map(item => {
                            return (
                                <Button className='hover:text-[#23F4F9]' key={v4()} href={item.link}>
                                    {item.linkText}
                                </Button>
                            )
                        })
                    }
                </NavItems>
            </Nav>
            <Drawer />
        </>
    )
}

export default Navbar