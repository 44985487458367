import React from 'react'
import { IconProps } from '../../types/types'

export default function IconDiscord({ color = '#111315', size = 22 }: IconProps) {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 22 22" fill="none" className='group'>
                <g clipPath="url(#clip0_2985_36860)">
                    <path d="M16.1379 1.37386C14.9529 0.862557 13.7019 0.499823 12.4168 0.294922C12.241 0.591179 12.0819 0.895988 11.9402 1.20807C10.5714 1.01368 9.17939 1.01368 7.81058 1.20807C7.6688 0.89602 7.5097 0.591215 7.33393 0.294922C6.04807 0.501554 4.79625 0.865149 3.61006 1.37653C1.25516 4.66014 0.616778 7.86218 0.935967 11.0188C2.31506 11.9791 3.85865 12.7094 5.49966 13.178C5.86916 12.7096 6.19613 12.2127 6.47708 11.6926C5.94345 11.5048 5.42839 11.273 4.93788 11.0001C5.06698 10.9118 5.19323 10.8209 5.31524 10.7327C6.7425 11.3652 8.30029 11.6932 9.87751 11.6932C11.4547 11.6932 13.0125 11.3652 14.4398 10.7327C14.5632 10.8276 14.6895 10.9185 14.8171 11.0001C14.3257 11.2735 13.8097 11.5057 13.2751 11.6939C13.5557 12.2138 13.8827 12.7103 14.2525 13.178C15.8949 12.7113 17.4397 11.9813 18.819 11.0201C19.1936 7.35948 18.1792 4.18685 16.1379 1.37386ZM6.88139 9.07749C5.99192 9.07749 5.25707 8.31676 5.25707 7.38088C5.25707 6.44499 5.96638 5.67757 6.87855 5.67757C7.79072 5.67757 8.51989 6.44499 8.50428 7.38088C8.48868 8.31676 7.78788 9.07749 6.88139 9.07749ZM12.8736 9.07749C11.9827 9.07749 11.2507 8.31676 11.2507 7.38088C11.2507 6.44499 11.96 5.67757 12.8736 5.67757C13.7872 5.67757 14.5107 6.44499 14.4951 7.38088C14.4795 8.31676 13.7801 9.07749 12.8736 9.07749Z" fill={color} className='group-hover:fill-[#23F4F9]' />
                </g>
                <defs>
                    <clipPath id="clip0_2985_36860">
                        <rect width="18.0363" height="12.8831" fill="white" transform="translate(0.859375 0.294922)" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}
