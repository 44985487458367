import React, { HTMLAttributes } from 'react';
import { styled } from 'styled-components';

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode,
    className?: string,
    maxWidth: string,
    bgColor?: string,
}

enum Sizes {
    xs = '320px',
    sm = '600px',
    md = '900px',
    lg = '1200px',
    xl = '1536px'
}

const DIV = styled.div<{ $size: string; $bgColor: string | undefined }>`
    width: 100%;
    max-width: ${props => props.$size};
    min-height: 100vh;
    margin: 0 auto;
    background-color: ${props => props.$bgColor ? props.$bgColor : '#FFFFFF'};;
`

const Container = ({ children, style, maxWidth, bgColor, className }: ContainerProps) => {
    return (
        <DIV className={className} $size={'1536px'} $bgColor={bgColor} style={style}>
            {children}
        </DIV>
    )
}

export default Container;