import React, { useEffect } from 'react'
import { v4 } from 'uuid';
import { styled } from 'styled-components';
import { useLayoutState } from '../../../context/LayoutContextProvider';
import IconCircleClose from '../../icons/IconCircleClose';
import Button from '../Button';
import Logo from '../Logo';
import { items } from './Navbar';

const DrawerBody = styled.div<{ $isOpen?: boolean }>`
    height: 100vh;
    width: 290px;
    max-width: 320px;
    border-radius: 0 15px 15px 0;
    position: absolute;
    inset: 0 0 0 0;
    transform: ${props => props.$isOpen ? 'translateX(0)' : 'translateX(-100%)'};
    transition: transform .3s ease-in-out;
`

const DrawerHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1.3rem 1rem;
`;

const DrawerContent = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: .5rem 1rem;
`;

const Drawer = () => {

    const { layoutState, updateLayoutState } = useLayoutState();

    const setActiveRoute = () => {
        let allLinks = document.querySelectorAll('#drawer ul a.active');
        for (let i = 0; i < allLinks.length; i++) {
            const element = allLinks[i];
            element?.classList.remove('active');
        }
        // Get the base pathname (e.g., /projects) from the current window location
        const basePathname = window.location.pathname;

        // Find the link with the base pathname
        const baseSelector = document.querySelector('#drawer ul a[href^="' + basePathname + '"]');
        baseSelector?.classList.add('active');

        // Find the link with the full pathname, including subroutes
        // const fullSelector = document.querySelector('nav ul a[href="' + window.location.pathname + '"]');
        // fullSelector?.classList.add('active');
    };

    useEffect(() => {
        setActiveRoute()
    }, [window.location.pathname, window.location.href, window.location, layoutState])

    return (
        <DrawerBody id='drawer' className='bg-neutral-dark-dark3 lg:hidden' $isOpen={layoutState.isMenuOpen}>
            <DrawerHeader>
                <Logo />
                <Button size='content' onClick={() => { updateLayoutState({ isMenuOpen: false }) }}>
                    <IconCircleClose color='#FFFFFF' />
                </Button>
            </DrawerHeader>
            <DrawerContent>
                {
                    items.map(item => {
                        return (
                            <Button href={item.link} key={v4()} className='text-heading-06 w-full py-2 cursor-pointer my-2 capitalize !justify-start'>
                                {item.linkText}
                            </Button>
                        )
                    })
                }
            </DrawerContent>
        </DrawerBody>
    )
}

export default Drawer