import React from 'react'
import { IconProps } from '../../types/types';

export default function IconYoutube({ color = '#111315', size = 22 }: IconProps) {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 22 22" fill="none" className='group'>
                <g clipPath="url(#clip0_2985_36876)">
                    <path d="M20.7943 6.18383C20.7943 6.18383 20.593 4.76267 19.973 4.13865C19.1879 3.31735 18.3103 3.31333 17.9077 3.26501C15.0251 3.05566 10.6972 3.05566 10.6972 3.05566H10.6891C10.6891 3.05566 6.36124 3.05566 3.47865 3.26501C3.07606 3.31333 2.1984 3.31735 1.41334 4.13865C0.793341 4.76267 0.596069 6.18383 0.596069 6.18383C0.596069 6.18383 0.386719 7.85461 0.386719 9.52135V11.0834C0.386719 12.7502 0.592043 14.4209 0.592043 14.4209C0.592043 14.4209 0.79334 15.8421 1.40931 16.4661C2.19437 17.2874 3.22502 17.2592 3.68398 17.3478C5.33462 17.5048 10.6932 17.5531 10.6932 17.5531C10.6932 17.5531 15.0251 17.5451 17.9077 17.3398C18.3103 17.2915 19.1879 17.2874 19.973 16.4661C20.593 15.8421 20.7943 14.4209 20.7943 14.4209C20.7943 14.4209 20.9996 12.7542 20.9996 11.0834V9.52135C20.9996 7.85461 20.7943 6.18383 20.7943 6.18383ZM8.56344 12.9796V7.1863L14.1313 10.093L8.56344 12.9796Z" fill={color} className='group-hover:fill-[#23F4F9]' />
                </g>
                <defs>
                    <clipPath id="clip0_2985_36876">
                        <rect width="20.6129" height="20.6129" fill="white" transform="translate(0.386719)" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}
