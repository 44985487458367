exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-coins-index-tsx": () => import("./../../../src/pages/coins/index.tsx" /* webpackChunkName: "component---src-pages-coins-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-fees-index-tsx": () => import("./../../../src/pages/fees/index.tsx" /* webpackChunkName: "component---src-pages-fees-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-plugins-index-tsx": () => import("./../../../src/pages/plugins/index.tsx" /* webpackChunkName: "component---src-pages-plugins-index-tsx" */)
}

