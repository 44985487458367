import React from 'react'
import { IconProps } from '../../types/types'

export default function IconMedium({ color = '#111315', size = 22 }: IconProps) {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 22 22" fill="none" className='group'>
                <g clipPath="url(#clip0_2985_36885)">
                    <path d="M12.2972 10.1572C12.2972 13.3953 9.69471 16.0203 6.48461 16.0203C3.27451 16.0203 0.671875 13.3947 0.671875 10.1572C0.671875 6.91972 3.27432 4.29395 6.48461 4.29395C9.69491 4.29395 12.2972 6.91913 12.2972 10.1572Z" fill={color} className='group-hover:fill-[#23F4F9]' />
                    <path d="M18.6741 10.157C18.6741 13.205 17.3729 15.6769 15.7677 15.6769C14.1626 15.6769 12.8613 13.205 12.8613 10.157C12.8613 7.10906 14.1624 4.63721 15.7675 4.63721C17.3727 4.63721 18.6739 7.10827 18.6739 10.157" fill={color} className='group-hover:fill-[#23F4F9]' />
                    <path d="M21.2824 10.1568C21.2824 12.8871 20.8249 15.1017 20.2603 15.1017C19.6957 15.1017 19.2383 12.8876 19.2383 10.1568C19.2383 7.42598 19.6959 5.21191 20.2603 5.21191C20.8247 5.21191 21.2824 7.42578 21.2824 10.1568Z" fill={color} className='group-hover:fill-[#23F4F9]' />
                </g>
                <defs>
                    <clipPath id="clip0_2985_36885">
                        <rect width="20.61" height="20.61" fill="white" transform="translate(0.671875)" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}
