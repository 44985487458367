import React from 'react'
import { IconProps } from '../../types/types';

export default function IconGithub({ color = '#111315', size = 22 }: IconProps) {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 25" fill="none" className='group'>
                <path d="M12.7656 0C6.13963 0 0.765625 5.373 0.765625 12C0.765625 17.302 4.20362 21.8 8.97262 23.387C9.57162 23.498 9.76562 23.126 9.76562 22.81V20.576C6.42762 21.302 5.73263 19.16 5.73263 19.16C5.18663 17.773 4.39963 17.404 4.39963 17.404C3.31063 16.659 4.48263 16.675 4.48263 16.675C5.68762 16.759 6.32163 17.912 6.32163 17.912C7.39163 19.746 9.12862 19.216 9.81363 18.909C9.92063 18.134 10.2316 17.604 10.5756 17.305C7.91063 17 5.10862 15.971 5.10862 11.374C5.10862 10.063 5.57762 8.993 6.34462 8.153C6.22062 7.85 5.80963 6.629 6.46163 4.977C6.46163 4.977 7.46962 4.655 9.76262 6.207C10.7196 5.941 11.7456 5.808 12.7656 5.803C13.7856 5.808 14.8126 5.941 15.7716 6.207C18.0626 4.655 19.0686 4.977 19.0686 4.977C19.7216 6.63 19.3106 7.851 19.1866 8.153C19.9566 8.993 20.4216 10.064 20.4216 11.374C20.4216 15.983 17.6146 16.998 14.9426 17.295C15.3726 17.667 15.7656 18.397 15.7656 19.517V22.81C15.7656 23.129 15.9576 23.504 16.5666 23.386C21.3316 21.797 24.7656 17.3 24.7656 12C24.7656 5.373 19.3926 0 12.7656 0Z" fill={color} className='group-hover:fill-[#23F4F9]' />
            </svg>
        </>
    )
}
