import React, { DOMAttributes, HTMLAttributes } from "react";
import { Link, GatsbyLinkProps } from "gatsby";

enum Sizes {
    sm = 'min-w-[60px] h-[40px] text-[14px] px-4 py-2',
    md = 'min-w-[90px] h-[45px] text-[16px] px-4 py-2',
    lg = 'min-w-[100px] h-[50px] text-[16px] px-4 py-2',
    content = 'min-w-min p-0'
};

enum Variants {
    contained = 'text-neutral-dark-dark1 bg-brand-main',
    outlined = 'bg-transparent border-2 border-neutral-dark-gray2',
    text = 'bg-transparent hover:bg-gray-300',
};

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode,
    className?: string,
    size?: 'sm' | 'md' | 'lg' | 'content',
    variant?: 'contained' | 'outlined' | 'text',
    disabled?: boolean,
    color?: `#${string}`
    href?: string,
    target?: React.HTMLAttributeAnchorTarget
};

export default function Button({ children, className, size = 'md', variant, disabled = false, color, href, target, ...other }: ButtonProps) {
    const classNames = `${variant && Variants[variant]} ${Sizes[size]} ${className} ${color ? `text-[${color}]` : 'text-[#FFFFFF]'}
            flex items-center justify-center shrink-0 gap-2 rounded-md capitalize disabled:opacity-50 transition-all duration-300 ease-in-out`
    return (
        <>
            {
                href ?
                    <Link
                        target={target}
                        to={href}
                        className={`${classNames}`}
                    >
                        {children}
                    </Link> :
                    <button
                        {...other}
                        className={classNames}>
                        {children}
                    </button>
            }
        </>
    )
}