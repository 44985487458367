import "./src/styles/global.css"
import React from 'react';

let loadingDiv;

export const onInitialClientRender = () => {
    // Hide loading screen once the app has loaded
    if (loadingDiv) {
        loadingDiv.style.display = 'none';
    }
};

export const onClientEntry = () => {
    // Create a loading div and append it to the body
    loadingDiv = document.createElement('div');
    loadingDiv.id = '___loader';
    loadingDiv.innerHTML = `
    <style>
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    </style>
    <div id="___loading">
      <div style="
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #111315; /* Change this to your desired background color */
        z-index: 9999;
      ">
        <div style="
          width: 60px;
          height: 60px;
          border: 6px solid #23F4F9;
          border-radius: 50%;
          border-top: 6px solid #111315;
          animation: spin 2s linear infinite;
        "></div>
      </div>
    </div>
  `;
    document.body.appendChild(loadingDiv);
};