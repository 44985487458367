import React from 'react';
import { styled } from 'styled-components';
import IconMenu from '../components/icons/IconMenu';
import Button from '../components/modules/Button';
import Logo from '../components/modules/Logo';
import Navbar from '../components/modules/navbar/Navbar';
import { useLayoutState } from '../context/LayoutContextProvider';
import { Helmet } from 'react-helmet';

const HeaderTag = styled.header`
    width: 100%;
    height: 4rem;
    padding: .5rem 1rem;
    background-color: #111315;
    position: sticky;
    top: 0;
    z-index: 999;

    @media (min-width: 768px) {
        padding: 0.5rem 1.5rem;
    }
`;

const ToolBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    gap: 8px;
`;

const isBrowser = typeof window !== 'undefined';

const Header = () => {
    const { updateLayoutState } = useLayoutState();

    return (
        <HeaderTag className='border-b-2 border-neutral-dark-outline'>
            <Helmet>
                {isBrowser && <script>{`window.loaded = true;`}</script>}
            </Helmet>
            <ToolBar>
                <div className='flex flex-1 gap-4'>
                    <Button className='lg:hidden' size='content' onClick={() => { updateLayoutState({ isMenuOpen: true }) }}>
                        <IconMenu color='#FFFFFF' />
                    </Button>
                    <Logo responsive />
                </div>
                {
                    isBrowser &&
                    <Navbar className='hidden lg:block' />
                }
                <div className='flex flex-1 justify-end gap-4'>
                    <Button target='_blank' href='https://app.aiopay.io/auth/login'>
                        login
                    </Button>
                    <Button target='_blank' href='https://app.aiopay.io/auth/register' variant='contained' color='#111315'>
                        signup
                    </Button>
                </div>
            </ToolBar>
        </HeaderTag>
    )
}

export default Header;