import React, { useEffect, useState } from 'react';
import Container from './Container';
import Header from './Header';
import LayoutProvider from '../context/LayoutContextProvider';
import Footer from './Footer';
import { globalHistory } from '@reach/router'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { graphql, useStaticQuery } from 'gatsby';

type LayoutProps = {
  children: React.ReactNode,
}

const Layout = ({ children }: LayoutProps) => {

  const data = useStaticQuery(graphql`
    query {
      starPattern: file(relativePath: { eq: "star-pattern.svg" }) {
        publicURL
      }
    }
  `);

  const starPattern = data.starPattern.publicURL;
  const [showStarPattern, setShowStarPattern] = useState(true);

  const hideStarPattern = () => {
    const basePathname = window.location.pathname;
    if (basePathname.includes('/about-us') || basePathname.includes('/contact-us')) {
      setShowStarPattern(false);
    } else {
      setShowStarPattern(true);
    }
  }

  useEffect(() => {
    hideStarPattern()
    globalHistory.listen(({ action }) => {
      hideStarPattern()
    })
  }, []);

  return (
    <LayoutProvider>
      <main className='relative bg-[#0C0E10]'>
        <Header />
        {
          showStarPattern &&
          <img className='absolute max-w-6xl w-full left-[50%] -translate-x-1/2 top-[500px] sm:top-96 md:top-80 xl:top-96' src={starPattern} alt='star-pattern' />
        }
        <Container maxWidth='xl' bgColor='bg-neutral-dark-dark1 z-20' className='px-3 py-6 md:px-6 overflow-x-hidden'>
          {children}
        </Container>
        <Footer />
      </main>
    </LayoutProvider>
  )
}

export default Layout;
