import React from 'react'
import { IconProps } from '../../types/types';

export default function IconTelegram({ color = '#111315', size = 22 }: IconProps) {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 22 22" fill="none" className='group'>
                <g clipPath="url(#clip0_2985_36872)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M20.9039 10.3065C20.9039 15.9985 16.2896 20.6129 10.5975 20.6129C4.90537 20.6129 0.291016 15.9985 0.291016 10.3065C0.291016 4.61436 4.90537 0 10.5975 0C16.2896 0 20.9039 4.61436 20.9039 10.3065ZM10.9674 7.60927C9.96499 8.02623 7.96149 8.88922 4.95695 10.1982C4.46906 10.3923 4.21348 10.5821 4.19021 10.7677C4.15089 11.0813 4.54368 11.2048 5.07855 11.373C5.1513 11.3959 5.22669 11.4196 5.30397 11.4447C5.8302 11.6158 6.53807 11.8159 6.90606 11.8238C7.23987 11.8311 7.61243 11.6934 8.02376 11.411C10.831 9.51603 12.2801 8.55822 12.3711 8.53757C12.4353 8.52301 12.5242 8.50469 12.5845 8.55825C12.6447 8.61182 12.6388 8.71326 12.6324 8.74047C12.5935 8.90635 11.0517 10.3398 10.2538 11.0816C10.005 11.3128 9.82862 11.4768 9.79255 11.5143C9.71175 11.5982 9.62941 11.6776 9.55026 11.7539C9.06138 12.2252 8.69477 12.5786 9.57056 13.1557C9.99143 13.4331 10.3282 13.6624 10.6642 13.8912C11.0311 14.1411 11.3971 14.3903 11.8706 14.7007C11.9913 14.7798 12.1065 14.862 12.2187 14.942C12.6457 15.2464 13.0294 15.5199 13.5033 15.4763C13.7787 15.4509 14.0632 15.192 14.2077 14.4196C14.5491 12.5943 15.2202 8.63951 15.3753 7.00982C15.3889 6.86704 15.3718 6.6843 15.3581 6.60409C15.3444 6.52387 15.3157 6.40958 15.2114 6.32498C15.0879 6.22478 14.8973 6.20365 14.812 6.20515C14.4244 6.21198 13.8297 6.41878 10.9674 7.60927Z" fill={color} className='group-hover:fill-[#23F4F9]' />
                </g>
                <defs>
                    <clipPath id="clip0_2985_36872">
                        <rect width="20.6129" height="20.6129" fill="white" transform="translate(0.291016)" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}
